import { v4 } from 'uuid';

import {
    CreativeRequest,
    CreativeRequestComment,
    CreativeRequestItem,
    CreativeRequestItemComment,
    Dictionary,
    CreativeRequestCommentInterface,
    CreativeRequestItemCommentInterface,
} from '@sbermarketing/mrm-metacom-client';

import { rtkApi } from './rtkApi';
import { MrmClient } from '../MrmClient';

export interface GetCreativeRequestCommentsData {
    creativeRequestId: string;
    creativeRequestItemId?: string | null;
}

export interface GetCreativeRequestCommentData extends GetCreativeRequestCommentsData {
    commentId: string;
}

export interface GetCreativeRequestItemData {
    creativeRequestId: string;
    creativeRequestItemId: string;
}

export interface EditCreativeRequestComment extends GetCreativeRequestCommentsData {
    commentId: string;
    isFavorite?: boolean;
    text?: string;
}

export interface AddCreativeRequestCommentFiles extends GetCreativeRequestCommentsData {
    commentId: string;
    fileIds: string[];
}

export interface AddCreativeRequestCommentData {
    creativeRequestId: string;
    creativeRequestItemId?: string;
    column?: string;
    text: string;
    replyId?: string;
    fileIds: string[];
}

export type CRComment = CreativeRequestCommentInterface | CreativeRequestItemCommentInterface;

export const creativeRequestApi = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getCreativeRequest: builder.query<CreativeRequest, string>({
            queryFn: async (creativeRequestId: string) => {
                try {
                    const client = await MrmClient.getInstance();

                    const data = await client.domain.creativeRequests.getCreativeRequest({
                        id: creativeRequestId,
                    });

                    return { data };
                } catch (e) {
                    console.error(e);
                    return { error: e };
                }
            },
        }),
        getCreativeRequestItems: builder.query<CreativeRequestItem[], string>({
            queryFn: async (creativeRequestId: string, { dispatch }) => {
                try {
                    const { data: creativeRequest } = await dispatch(
                        creativeRequestApi.endpoints.getCreativeRequest.initiate(creativeRequestId),
                    );

                    const data: CreativeRequestItem[] = await creativeRequest.model.getItems();

                    return { data };
                } catch (e) {
                    console.error(e);
                    return { data: [] };
                }
            },
            onCacheEntryAdded: async (creativeRequestId, { cacheDataLoaded, cacheEntryRemoved, dispatch }) => {
                await cacheDataLoaded;

                const listener = async () => {
                    dispatch(creativeRequestApi.util.invalidateTags(['creative-request-item']));
                };

                const { data: creativeRequest } = await dispatch(
                    creativeRequestApi.endpoints.getCreativeRequest.initiate(creativeRequestId),
                );

                creativeRequest.events.onItemsAdded(listener);
                await cacheEntryRemoved;
                creativeRequest.events.offItemsAdded(listener);
            },
            providesTags: (result) =>
                [
                    'creative-request-item',
                    ...result.map((item) => ({ type: 'creative-request-item', id: item.model.id })),
                ] as any,
        }),
        getCreativeRequestItem: builder.query<CreativeRequestItem, GetCreativeRequestItemData>({
            queryFn: async ({ creativeRequestId, creativeRequestItemId }, { dispatch }) => {
                try {
                    const { data: items } = await dispatch(
                        creativeRequestApi.endpoints.getCreativeRequestItems.initiate(creativeRequestId),
                    );

                    const data: CreativeRequestItem = items.find(({ model }) => model.id === creativeRequestItemId);

                    return { data };
                } catch (e) {
                    console.error(e);
                    return { error: e };
                }
            },
            providesTags: (result) => [{ type: 'creative-request-item', id: result.model.id }],
        }),
        getCreativeRequestComments: builder.query<
            (CreativeRequestComment | CreativeRequestItemComment)[],
            GetCreativeRequestCommentsData
        >({
            queryFn: async ({ creativeRequestId, creativeRequestItemId }, { dispatch }) => {
                try {
                    if (creativeRequestItemId) {
                        const { data: item } = await dispatch(
                            creativeRequestApi.endpoints.getCreativeRequestItem.initiate({
                                creativeRequestId,
                                creativeRequestItemId,
                            }),
                        );

                        const data: CreativeRequestItemComment[] = await item.model.getComments();

                        return { data };
                    }

                    const { data: creativeRequest } = await dispatch(
                        creativeRequestApi.endpoints.getCreativeRequest.initiate(creativeRequestId),
                    );

                    const data: CreativeRequestComment[] = await creativeRequest.model.getComments();

                    if (creativeRequestItemId !== '') {
                        return { data };
                    }

                    const { data: creativeRequestItems } = await dispatch(
                        creativeRequestApi.endpoints.getCreativeRequestItems.initiate(creativeRequestId),
                    );
                    const comments: CreativeRequestItemComment[] = (
                        await Promise.all(creativeRequestItems.map((item) => item.model.getComments()))
                    ).flat();

                    return { data: [...data, ...comments] };
                } catch (e) {
                    console.error(e);
                    return { error: e };
                }
            },
            onCacheEntryAdded: async (
                { creativeRequestId, creativeRequestItemId },
                { cacheDataLoaded, cacheEntryRemoved, dispatch },
            ) => {
                await cacheDataLoaded;

                const listener = async () => {
                    dispatch(creativeRequestApi.util.invalidateTags(['creative-request-comment']));
                };

                if (creativeRequestItemId) {
                    const { data: item } = await dispatch(
                        creativeRequestApi.endpoints.getCreativeRequestItem.initiate({
                            creativeRequestId,
                            creativeRequestItemId,
                        }),
                    );

                    item.events.onUpdated(listener);
                    await cacheEntryRemoved;
                    item.events.offUpdated(listener);
                } else {
                    const { data: creativeRequest } = await dispatch(
                        creativeRequestApi.endpoints.getCreativeRequest.initiate(creativeRequestId),
                    );

                    creativeRequest.events.onUpdated(listener);
                    await cacheEntryRemoved;
                    creativeRequest.events.offUpdated(listener);
                }
            },
            providesTags: (comments) =>
                [
                    'creative-request-comment',
                    ...comments?.map((comment) => ({
                        type: 'creative-request-comment',
                        id: comment.model?.id,
                    })),
                ] as any,
        }),
        getCreativeRequestComment: builder.query<
            CreativeRequestComment | CreativeRequestItemComment,
            GetCreativeRequestCommentData
        >({
            queryFn: async ({ creativeRequestId, creativeRequestItemId, commentId }, { dispatch }) => {
                try {
                    const { data: comments } = (await dispatch(
                        creativeRequestApi.endpoints.getCreativeRequestComments.initiate({
                            creativeRequestId,
                            creativeRequestItemId,
                        }),
                    )) as { data: CreativeRequestComment[] };

                    const data: CreativeRequestComment | CreativeRequestItemComment = comments.find(
                        ({ model }: CreativeRequestComment | CreativeRequestItemComment) => model.id === commentId,
                    );

                    return { data };
                } catch (e) {
                    console.error(e);
                    return { error: e };
                }
            },
        }),
        addCreativeRequestComment: builder.mutation<null, AddCreativeRequestCommentData>({
            queryFn: async (
                { text, fileIds = [], replyId, creativeRequestId, creativeRequestItemId, column },
                { dispatch },
            ) => {
                try {
                    const id = v4();

                    await fetch('/api/graphql', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            operationName: 'createComment',
                            variables: { fileIds, id, text, replyId: replyId ?? undefined },
                            query: 'mutation createComment($id: String!, $text: String!, $fileIds: [String], $replyId: String) {\n  createComment(comment: {id: $id, text: $text, fileIds: $fileIds, replyId: $replyId})\n}\n',
                        }),
                    });

                    if (creativeRequestItemId) {
                        const { data: item } = await dispatch(
                            creativeRequestApi.endpoints.getCreativeRequestItem.initiate({
                                creativeRequestId,
                                creativeRequestItemId,
                            }),
                        );

                        await item.model.addComment({
                            commentId: id,
                            column,
                            creativeRequestId,
                        });
                    } else {
                        const { data: creativeRequest } = await dispatch(
                            creativeRequestApi.endpoints.getCreativeRequest.initiate(creativeRequestId),
                        );
                        await creativeRequest.model.addComment({ commentId: id });
                    }

                    return { data: null };
                } catch (e) {
                    console.error(e);
                    return { error: e };
                }
            },
        }),
        getCreativeRequestGroup: builder.query<Dictionary, GetCreativeRequestItemData>({
            queryFn: async ({ creativeRequestId, creativeRequestItemId }, { dispatch }) => {
                try {
                    const { data: item } = await dispatch(
                        creativeRequestApi.endpoints.getCreativeRequestItem.initiate({
                            creativeRequestId,
                            creativeRequestItemId,
                        }),
                    );

                    const data: Dictionary = await item.model.creativeRequestGroup;

                    return { data };
                } catch (e) {
                    console.error(e);
                    return { error: e };
                }
            },
        }),
        editCreativeRequestComment: builder.mutation<null, EditCreativeRequestComment>({
            queryFn: async (
                { creativeRequestId, creativeRequestItemId, commentId, text, isFavorite },
                { dispatch },
            ) => {
                try {
                    const { data: comment } = await dispatch(
                        creativeRequestApi.endpoints.getCreativeRequestComment.initiate({
                            creativeRequestId,
                            creativeRequestItemId,
                            commentId,
                        }),
                    );

                    if (isFavorite !== undefined && 'setFavorite' in comment.model) {
                        await comment.model.setFavorite({ isFavorite });
                    }

                    if (text !== undefined) {
                        await comment.model.setText({ text });
                    }

                    return { data: null };
                } catch (e) {
                    console.error(e);
                    return { error: e };
                }
            },
        }),
        addCreativeRequestCommentFiles: builder.mutation<null, AddCreativeRequestCommentFiles>({
            queryFn: async ({ creativeRequestId, fileIds, commentId, creativeRequestItemId }, { dispatch }) => {
                try {
                    const { data: comment } = await dispatch(
                        creativeRequestApi.endpoints.getCreativeRequestComment.initiate({
                            creativeRequestId,
                            creativeRequestItemId,
                            commentId,
                        }),
                    );

                    await comment.model.addFiles({ fileIds });

                    return { data: null };
                } catch (e) {
                    console.error(e);
                    return { error: e };
                }
            },
        }),
        removeCreativeRequestCommentFiles: builder.mutation<null, AddCreativeRequestCommentFiles>({
            queryFn: async ({ creativeRequestId, fileIds, commentId, creativeRequestItemId }, { dispatch }) => {
                try {
                    const { data: comment } = await dispatch(
                        creativeRequestApi.endpoints.getCreativeRequestComment.initiate({
                            creativeRequestId,
                            creativeRequestItemId,
                            commentId,
                        }),
                    );

                    await comment.model.removeFiles({ fileIds });

                    return { data: null };
                } catch (e) {
                    console.error(e);
                    return { error: e };
                }
            },
        }),
    }),
});

export const {
    useGetCreativeRequestCommentsQuery,
    useGetCreativeRequestItemsQuery,
    useGetCreativeRequestQuery,
    useGetCreativeRequestItemQuery,
    useAddCreativeRequestCommentMutation,
    useGetCreativeRequestGroupQuery,
    useGetCreativeRequestCommentQuery,
    useEditCreativeRequestCommentMutation,
    useAddCreativeRequestCommentFilesMutation,
    useRemoveCreativeRequestCommentFilesMutation,
} = creativeRequestApi;
